<template>
	<div class="exchange">
		<!-- <div style="background-color: rgb(253, 89, 108); border-radius: 10px; width: 1036px; text-align: center; margin: 10px 0px; color: white">
			<h3>3月29-4月1號門店休息，2號正常營業，謝謝！</h3>
		</div> -->
		<div class="home_img" id="home_img">
		</div>

		<div class="code">
			<img src="@/assets/home/down.png" />
		</div>
		<div class="text">
			<p>
				最新匯率
				<span class="C2">僅供參考</span>
			</p>
			<p class="D8">***實際價格以店鋪為準***</p>
			<p class="last_">注意：我司發現有不法之徒冒用我司名義進行詐騙活動， 在FACEBOOK,WHATSAPP等冒用我司職員</p>
			<p class="last_">透過WHATSAPP等聯絡，要求客人網上轉賬，請客人不要網上轉賬，如閣下收到此類信息，請勿輕信，以免造成不必要損失，謝謝!</p>
		</div>
		<p class="time">{{ rateLastTime }}</p>

		<!-- 汇率表 -->
		<div class="rate">
			<el-table :data="tableData" cell-style="border:1px solid #d94d5d" header-cell-style="border:1px solid #d94d5d" border stripe style="width: 100%">
				<el-table-column label="" :width="52" align="center">
					<template slot-scope="scope">
						<!-- <el-image :src="'https:/api.xiaonvh.com' + scope.row.iconUrl" style="width: 30px; height: 30px"></el-image> -->
						<el-image :src="'https://xnh-1259348577.cos.ap-hongkong.myqcloud.com/' + scope.row.iconUrl" style="width: 30px; height: 30px"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="currency / 貨幣" align="center">
					<template slot-scope="scope">
						<span style="margin-left: 5px">
							{{ scope.row.symbol + '&nbsp;' + scope.row.nameCnHant }}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="We Buy Notes / 现钞買入" align="center">
					<template slot-scope="scope">
						<span>
							{{ scope.row.buyRate }}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="We Sell Notes / 现钞買出" align="center">
					<template slot-scope="scope">
						<span>
							{{ scope.row.sellRate }}
						</span>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<bottom></bottom>
	</div>
</template>

<script>
	import bottom from '@/components/bottom.vue';
	import axios from 'axios';
	export default {
		data() {
			return {
				currentTime: new Date(),
				rateLastTime: '',
				tableData: []
			};
		},
		components: {
			bottom
		},
		computed: {
			formattedTime() {
				const year = this.currentTime.getFullYear();
				const month = String(this.currentTime.getMonth() + 1).padStart(2, '0');
				const day = String(this.currentTime.getDate()).padStart(2, '0');
				const hour = String(this.currentTime.getHours()).padStart(2, '0');
				const minute = String(this.currentTime.getMinutes()).padStart(2, '0');
				return `${year}年${month}月${day}日 ${hour}:${minute}`;
			},
			screenWidth() {
				return window.innerWidth;
			}
		},
		created() {
			const that = this;
			axios({
				method: 'post',
				url: 'https://api.xiaonvh.com/app/currency/getAllRecentlyDeptList?pageNum=2&pageSize=50',
				// url: 'http://localhost:8088/app/currency/getAllRecentlyDeptList?pageNum=2&pageSize=50',
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(function(response) {
				if (response.data.code === 200) {
					that.tableData = [...response.data.list];
					that.rateLastTime = response.data.updateTime;
					console.log(response.data.list);
				}
			});

			axios({
				method: 'get',
				url: 'https://api.xiaonvh.com/app/views/savePageViews/0'
			});

			this.displayImage("https://xnh-1259348577.cos.ap-hongkong.myqcloud.com/image/exchange/SpringFestivalholidaynotice.jpg");
		},
		mounted() {
			setInterval(() => {
				this.currentTime = new Date();
			}, 10000); // 更新时间间隔为10秒
		},
		methods: {
			checkImage(url, callback) {
				const img = new Image();
				img.onload = () => callback(true); // 图片加载成功
				img.onerror = () => callback(false); // 图片加载失败
				img.src = url;
			},
			displayImage(url) {
				this.checkImage(url, (isAccessible) => {
					if (isAccessible) {
						const imgElement = document.createElement("img");
						imgElement.src = url;
						imgElement.style.width = "100%";
						document.getElementById("home_img").appendChild(imgElement);
					} else {
						console.log("图片无法访问:", url);
					}
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	::v-deep .el-table__body td {
		padding: 0;
	}

	.exchange {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;


		.home_img {
			width: 395px;
			height: auto;
			margin-bottom: 20px;

			img {
				width: 100%;
			}
		}

		.code {
			width: 1036px; //86vw;

			img {
				width: 100%;
			}
		}

		.text {
			text-align: center;
			font-size: 20px;
			line-height: 14px;
			font-weight: 550;
			margin-top: 3vh;

			.C2 {
				color: #c23b3b;
			}

			.D8 {
				color: #8d2424;
			}

			.last_ {
				font-size: 11px;
				color: #aaaaaa;
			}
		}

		.time {
			font-size: 20px;
			color: #3f3f3f;
			font-weight: 550;
		}

		.rate {
			width: 800px; //94vw
			height: auto;
			margin-bottom: 6vh;

			.el-table {
				border: 1px solid #d94d5d;
				background-color: #d94d5d;
				font-size: 10px;
			}
		}
	}

	@media screen and (max-width: 584px) {
		.rate {
			width: 94vw !important;
		}

		.last_ {
			width: 80vw !important;
		}
	}

	@media screen and (max-width: 1042px) {
		.code {
			width: 96vw !important;
		}
	}
</style>